import { useEffect, useState } from 'react';
import { getUserInfo } from '@buzzfeed/bf-utils/lib/user';
import { CLUSTER } from '../constants';

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (getUserInfo()) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const signIn = () => {
    const baseUrl = CLUSTER !== 'prod' ? 'https://stage.buzzfeed.com' : 'https://www.buzzfeed.com';
    const redirectUrl = window.top.location.href;
    window.top.location.href = `${baseUrl}/auth/signin?provider=auth0&redirect=${encodeURIComponent(redirectUrl)}`;
  };

  return { isAuthenticated, signIn };
};
