const NextI18Next = require('next-i18next').default;
const publicRuntimeConfig = require('../../env.config');
const assetPrefix = publicRuntimeConfig.ASSET_PREFIX;
const localeHash = require('../../../locale-hash.json');

const fromEditionDetector = {
  name: 'from-edition-detector',
  lookup: function(req) {
    if (!(req.headers && req.headers['x-bf-user-edition'])) {
      return null;
    }
    // x-bf-user-edition is an IETF language code (en-us) set at the cdn
    return req.headers['x-bf-user-edition'].split('-')[0];
  },
};

const NextI18NextInstance = new NextI18Next({
  defaultLanguage: 'en',
  otherLanguages: ['de', 'es', 'ja', 'pt'],
  localePath: 'src/public/static/locales',
  detection: {
    order: ['from-edition-detector', 'cookie'],
  },
  customDetectors: [fromEditionDetector],
  backend: {
    loadPath: (lngs, namespaces) => {
      const localeKey = `${lngs}/${namespaces}`;

      return typeof window === 'undefined' || !localeHash[localeKey]
        ? 'src/public/static/locales/{{lng}}/{{ns}}.json' //server
        : `${assetPrefix}/_next/static/locales/{{lng}}/{{ns}}.${localeHash[localeKey]}.json`; // client
    },
  },
});

module.exports = NextI18NextInstance;
