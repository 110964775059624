/**
 * ⚠️ these will be exposed on the client ⚠️
 */

const { settings } = require('@buzzfeed/rig');
const NODE_ENV = process.env.NODE_ENV || 'production';

/**
 * Use CDN in stage & prod
 *   To serve assets directly from service set to '' for all environments
 *   To serve assets from a different domain to your application, change to appropriate domain.
 *   Note, it's highly likely that you will want to keep the /static-assets subpath.
 *
 *   We use buzzfeed.com below as it guarantees that assets will be routed correctly
 *   for applications regardless of how they're deployed. Bear in mind, it is more performant to
 *   serve assets from the same domain as your application though.
 */
const ASSET_PREFIX =
  NODE_ENV === 'production'
    ? '/static-assets'
    : '';

const envConfig = {
  ASSET_PREFIX,
  CLUSTER: settings.get('cluster'),
  NODE_ENV,
};

Object.assign(envConfig, settings.get('client'));

module.exports = envConfig;
