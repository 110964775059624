import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { GLOBAL_NAMESPACE as CET_NAMESPACE } from '@buzzfeed/client-event-tracking';

export function useDocumentReferrerPolyfill() {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      /**
       * Before a client side route change occurs, save the current page URL to a referrer var.
       * Using document.referrer will return whatever the first page loaded was. Per NextJS - if
       * wanting to know client side history for referrer without actually navigating back - need to
       * track ourselves: https://github.com/zeit/next.js/issues/3565
       */
      window[CET_NAMESPACE] = {
        ...(window[CET_NAMESPACE] || {}),
        referrer: document.location.href,
      };
    };

    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
