import '../styles/main.scss';
import i18n from '../i18n';
import ErrorPage from 'next/error';
import { useDocumentReferrerPolyfill } from '../client-event-tracking/hooks';
import { ErrorBoundary } from '@buzzfeed/react-components';
import * as Sentry from '@sentry/nextjs';
import { TrackingContext } from '../context/TrackingContext';
import { AuthContext } from '../context/AuthContext';
import { useAuth } from '../hooks/useAuth';
require.context('../public/static/locales', true, /\.json$/);

// local development - a11y logging
import '../utils/reactAxeSetup';

function Application({ Component, pageProps }) {
  useDocumentReferrerPolyfill();
  const { isAuthenticated, signIn } = useAuth();

  return (
    <ErrorBoundary fallbackRender={() => (
        <ErrorPage
          statusCode={500}
          title="An unexpected error has occurred"
        />
      )}
      onError={(e) => {Sentry.captureException(e)}}
    >
      <AuthContext.Provider value={{ isAuthenticated, signIn }}>
        <TrackingContext.Provider value={{ ...pageProps.pageInfo }}>
          <Component {...pageProps} />
        </TrackingContext.Provider>
      </AuthContext.Provider>
    </ErrorBoundary>
  );
}

export default i18n.appWithTranslation(Application);
